import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shp-expectation',
  templateUrl: './expectation.component.html',
  styleUrls: ['./expectation.component.scss']
})
export class ExpectationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
