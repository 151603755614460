import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { IntroComponent } from './components/intro/intro.component';
import { OfferComponent } from './components/offer/offer.component';
import { ExpectationComponent } from './components/expectation/expectation.component';
import { NobComponent } from './components/nob/nob.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StaticHomepageRoutingModule } from './static-homepage.routes';
import { WavesComponent } from './components/waves/waves.component';
import { CarouselComponent } from './components/carousel/carousel.component';



@NgModule({
  declarations: [
    HomepageComponent,
    IntroComponent,
    OfferComponent,
    ExpectationComponent,
    NobComponent,
    WavesComponent,
    CarouselComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule
  ]
})
export class StaticHomepageModule { }
