import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomepageComponent } from './pages/homepage/homepage.component';


const homepageRoutes: Routes = [
  {
    path: '',
    component: HomepageComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(homepageRoutes)
  ],
  exports: [
      RouterModule
  ],
  providers: [

  ]
})

export class StaticHomepageRoutingModule { }
