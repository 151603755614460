import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shp-nob',
  templateUrl: './nob.component.html',
  styleUrls: ['./nob.component.scss']
})
export class NobComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
